// USA
export const locale = {
  admin_login: 'Admin Login',
  login: 'Login',
  email: 'Email',
  password: 'Password',
  you_are_not_admin: 'You are not an Admin!',
  dashboard: 'Dashboard',
  user: 'User',
  organizer: 'Organizer',
  single_user: 'Single User',
  guest: 'Guest',
  unapproved: 'Unapproved',
  unapproved_organizer: 'Unapproved Organizer',
  event: 'Event',
  post: 'Post',
  transaction: 'Transaction',
  refund: 'Refund',
  refund_fee: 'Refund Fee',
  handling_fee: 'Handling Fee',
  withdraw: 'Payout',
  report: 'Report',
  reported_user: 'Reported User',
  reported_post: 'Reported Post',
  reported_event: 'Reported Event',
  system: 'System',
  change_password: 'Change Password',
  send_message_to_users: 'Send message to users',
  send_email_to_users: 'Send email to users',
  logout: 'Logout',
  confirm_logout: 'Are you sure you want to logout now?',
  deleted: 'Deleted',
  sale: 'Sale',
  gross_sale: 'Gross Sale',
  net_sale: 'Net Sale',
  refunded: 'Refunded',
  please_wait: 'Please wait ...',
  online: 'Online',
  approve: 'Approve',
  decline: 'Decline',
  no_data_found: 'No data found!',
  confirm_approve_organizer: 'Are you sure you want to approve this user as Organizer?',
  confirm_approve_single_user: 'Are you sure you want to approve this user as Single User?',
  success: 'Success',
  ticket_price: 'Ticket Price',
  promo_discount: 'Promo Discount',
  service_charge: 'Service Charge',
  total_price: 'Total Price',
  discounted_: 'Discounted {0}% - {1}',
  tickets: 'Tickets',
  paid: 'Paid',
  paid_by: 'Paid by',
  requested: 'Requested',
  requested_by: 'Requested by',
  refund_canceled_by: 'Refund Canceled by',
  total_balance: 'Total Balance',
  invoice: 'Invoice',
  sales_amount: 'Sales Amount',
  payout_amount: 'Payout Amount',
  not_paid: 'Not Paid',
  already_paid: 'Already Paid',
  pending: 'Pending',
  refund_now: 'Refund Now',
  cancel_refund: 'Cancel Refund',
  remain_balance: 'Remain Balance',
  withdraw_amount: 'Withdraw Amount',
  status: 'Status',
  payment_done: 'Payment Done',
  confirm_withdraw_done: 'Are you sure you have already done this withdraw request?',
  confirm_delete_report: 'Are you sure you want to delete this report?',
  current_password: 'Current Password',
  new_password: 'New Password',
  confirm_password: 'Confirm Password',
  modify: 'Modify',
  password_does_not_match: 'Password does not match!',
  admin_password_changed_login_again: 'The admin password is changed successfully, please login again.',
  current_password_incorrect: 'The current password is incorrect.',
  title: 'Title',
  message: 'Message',
  send: 'Send',
  confirm_send_message: 'Are you sure you want to send this message to {0} users?',
  confirm_delete_message: 'Are you sure you want to delete this message?',
  confirm_delete_story: 'Are you sure you want to delete this Story?',
  confirm_delete_album: 'Are you sure you want to delete this Album?',
  report_type_1: 'Spam',
  report_type_2: 'Violence',
  report_type_3: 'Nudity',
  report_type_4: 'Hate Speech',
  report_type_5: 'Terrorism',
  report_type_6: 'Unauthorized Sales',
  select_month: 'Select month',
  select_date_range: 'Select date range',
  private: '(Private)',
  confirm_delete: 'Are you sure you want to delete?',
  drink: 'Drink',
  orders: 'Orders',
  paid_online: 'Paid online',
  paid_by_card: 'EC/CreditCard',
  paid_by_cash: 'Paid by Cash',
  tip: 'Tip',
  request_refund: 'Request Refund',
  select_event: 'Select Event',
  select_organizer: 'Select Organizer',
  add_event: 'Add Event',
  clear_selection: 'Clear Selection',
  payout_request: 'Payout Request',
  confirm_request_drink_payout: 'Are you sure you want to request the drink payout for all past events now?',
  remind: 'Remind',
  tag: 'Tag',
  tax_code: 'Tax Code',
  event_days: 'Days',
  cancel_withdraw: 'Cancel Payout',
};