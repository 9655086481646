import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: '/dashboard',
      component: () => import('@/view/layout/Layout'),
      children: [
        {
          path: '/dashboard',
          name: 'Dashboard',
          component: () => import('@/view/pages/Dashboard.vue')
        },
        {
          path: '/users/:type',
          name: 'UserList',
          component: () => import('@/view/pages/user/UserList.vue')
        },
        {
          path: '/users/:type/:userId',
          name: 'UserDetail',
          component: () => import('@/view/pages/user/UserDetail.vue')
        },
        {
          path: '/event/events/:userId?',
          name: 'EventList',
          component: () => import('@/view/pages/event/EventList.vue')
        },
        {
          path: '/event/posts',
          name: 'PostList',
          component: () => import('@/view/pages/event/PostList.vue')
        },
        {
          path: '/album',
          name: 'AlbumList',
          component: () => import('@/view/pages/album/AlbumList.vue')
        },
        {
          path: '/album/:albumId',
          name: 'AlbumDetail',
          component: () => import('@/view/pages/album/AlbumDetail.vue')
        },
        {
          path: '/story',
          name: 'StoryList',
          component: () => import('@/view/pages/story/StoryList.vue')
        },
        {
          path: '/transaction/transactions/u/:userId',
          name: 'TransactionListByUserId',
          component: () => import('@/view/pages/transaction/TransactionList.vue')
        },
        {
          path: '/transaction/transactions/:eventId?',
          name: 'TransactionListByEventId',
          component: () => import('@/view/pages/transaction/TransactionList.vue')
        },
        {
          path: '/transaction/refund/:eventId?',
          name: 'RefundListByEventId',
          component: () => import('@/view/pages/transaction/RefundList.vue')
        },
        {
          path: '/transaction/withdraws/u/:userId',
          name: 'WithdrawListByUserId',
          component: () => import('@/view/pages/transaction/WithdrawList.vue')
        },
        {
          path: '/transaction/withdraws/:eventId?',
          name: 'WithdrawListByEventId',
          component: () => import('@/view/pages/transaction/WithdrawList.vue')
        },
        {
          path: '/transaction/tickets/:transactionId',
          name: 'TicketList',
          component: () => import('@/view/pages/transaction/TicketList.vue')
        },
        {
          path: '/drink/drinks/u/:userId',
          name: 'DrinkListByUserId',
          component: () => import('@/view/pages/drink/DrinkList.vue')
        },
        {
          path: '/drink/drinks',
          name: 'DrinkList',
          component: () => import('@/view/pages/drink/DrinkList.vue')
        },
        {
          path: '/drink/orders/u/:userId?',
          name: 'DrinkOrderListByUserId',
          component: () => import('@/view/pages/drink/DrinkOrderList.vue')
        },
        {
          path: '/drink/orders/:eventId?',
          name: 'DrinkOrderList',
          component: () => import('@/view/pages/drink/DrinkOrderList.vue')
        },
        {
          path: '/drink/payout/u/:userId?',
          name: 'DrinkPayoutListByUserId',
          component: () => import('@/view/pages/drink/DrinkPayoutList.vue')
        },
        {
          path: '/drink/payout/:eventId?',
          name: 'DrinkPayoutList',
          component: () => import('@/view/pages/drink/DrinkPayoutList.vue')
        },
        {
          path: '/report/:type',
          name: 'ReportList',
          component: () => import('@/view/pages/report/ReportList.vue')
        },
        {
          path: '/system/change_password',
          name: 'ChangePassword',
          component: () => import('@/view/pages/system/ChangePassword.vue')
        },
        {
          path: '/system/send_message',
          name: 'SendMessage',
          component: () => import('@/view/pages/system/SendMessage.vue')
        },
        {
          path: '/system/send_email',
          name: 'SendEmail',
          component: () => import('@/view/pages/system/SendEmail.vue')
        },
        {
          path: '/forMa/paypalWebHook',
          name: 'PayPalWebHook',
          component: () => import('@/view/pages/forMa/PayPalWebHook.vue')
        },
        {
          path: '/forMa/temp',
          name: 'TransactionTemp',
          component: () => import('@/view/pages/forMa/TransactionTemp.vue')
        },
        {
          path: '/forMa/vip_ticket_link',
          name: 'VIPTicketLink',
          component: () => import('@/view/pages/forMa/VIPTicketLink.vue')
        },
        {
          path: '/forMa/buyTicket',
          name: 'BuyTicket',
          component: () => import('@/view/pages/forMa/BuyTicket.vue')
        },
        {
          path: '/forMa/editDocument',
          name: 'EditDocument',
          component: () => import('@/view/pages/forMa/EditDocument.vue')
        },
        {
          path: '/forMa/setHandlingFee',
          name: 'SetHandlingFee',
          component: () => import('@/view/pages/forMa/SetHandlingFee.vue')
        }
      ]
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/view/pages/Login.vue')
    },
    {
      path: '*',
      redirect: '/404'
    },
    {
      // the 404 route, when none of the above matches
      path: '/404',
      name: '404',
      component: () => import('@/view/pages/Page404.vue')
    }
  ]
});
